.content-error-message {
  color: #d8000c;
  padding: 15px 15px;
  border-radius: 8px;
  font-size: 16px;
  min-width: 80%;
  margin-top: 10px;
}

.search-bar {
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #f4f4f4;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  min-width: 200px;
  margin: 0px auto;
}
.search-bar input {
  padding: 15px;
  margin-right: 5px;
  border: 2px solid #ccc;
  font-size: 1rem;
}

.search-bar input {
  flex-grow: 1;
  border-radius: 5px;
  min-width: 200px;
}

.content-container {
  display: grid;
  grid-auto-rows: 60px;
  grid-template-columns: repeat(7, 1fr); /* This creates 7 columns */
  gap: 10px;
  align-items: start;
}

.content-header {
  font-weight: bold;
  display: contents;
}

.content-item {
  display: contents;
}

.content-item div {
  padding: 5px;
  text-align: center;
  background-color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 4px solid #ddd;
  transition: background-color 0.3s ease;
  cursor: pointer;
  border-radius: 10px;
  max-width: 100px; /* Minimum width to keep boxes consistent */
  min-width: 100px;
  height: 20px; /* Ensure all items have the same height */
}

.content-item div:hover {
  background-color: #cbdceb;
  border: 4px solid #133e87;
}

.content-header div {
  padding: 5px;
  text-align: center;
  background-color: #608bc1;
  border: 1px solid white;
}

.content-item div:active {
  background-color: #133e87;
  color: white;
}

.content-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  padding-bottom: 60px; /* To prevent overlap with the fixed bottom section */
}

.content-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  align-items: center;
  padding: 10px;
  flex: 1; /* Ensures content takes up remaining space */
}

.content-header {
  font-weight: bold;
  display: contents;
}

.content-item {
  display: contents;
}

.content-item div {
  padding: 5px;
  text-align: center;
  background-color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 4px solid #ddd;
  transition: background-color 0.3s ease;
  cursor: pointer;
  border-radius: 10px;
}

.content-item div:hover {
  background-color: #cbdceb;
  border: 4px solid #133e87;
}

.content-header div {
  padding: 5px;
  text-align: center;
  background-color: #608bc1;
  border: 1px solid white;
}

.content-item div:active {
  background-color: #133e87;
  color: white;
}

.content-pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.content-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.pagination-button {
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #333;
}

.pagination-button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.page-number {
  cursor: pointer;
  padding: 5px;
  font-size: 1rem;
}

.page-number:hover {
  color: #133e87;
}

.active {
  font-weight: bold;
  color: #f3f3e0;
  background-color: #608bc1;
  padding: 5px 10px;
  border-radius: 5px;
}

.page-number:active {
  color: #133e87;
}

.page-number {
  display: inline-block;
  padding: 5px;
}

.add-word-button {
  padding: 10px 20px;
  background-color: #133e87;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-word-button:hover {
  background-color: #1a4b8a;
}
