body {
  font-family: "Roboto", Georgia;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f3f3e0;
}

.dictionary-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.dictionary-page h1 {
  font-size: 3em;
  color: #133e87;
  margin-bottom: 20px;
}

/*SEARCH BAR*/
.dictionary-search-bar-container {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  min-width: 800px;
  margin: 20px auto;
}

.dictionary-search-bar-container select,
.dictionary-search-bar-container input,
.dictionary-search-bar-container button {
  padding: 15px;
  margin-right: 5px;
  border: 2px solid #ccc;
  font-size: 1rem;
}

.dictionary-search-bar-container select {
  width: 150px;
  background-color: #608bc1;
  border-radius: 5px;
  color: white;
}

.dictionary-search-bar-container input {
  flex-grow: 1;
  border-radius: 5px;
  min-width: 200px;
}

.dictionary-search-bar-container button {
  background-color: #608bc1;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 30px;
}

.dictionary-search-bar-container button:hover {
  background-color: #cbdceb;
  color: black;
}

/*ERROR MESSAGE*/
.dictionary-error-message {
  color: #d8000c;
  padding: 15px 15px;
  border-radius: 8px;
  font-size: 16px;
  min-width: 80%;
  margin-top: 10px;
}
/*TRANSLATION BOX*/
.dictionary-boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  width: 100%;
  margin-top: 20px;
  align-items: center;
  max-width: 1800px;
  justify-content: center; /* Center boxes horizontally */
}

.dictionary-boxTitle {
  font-size: xx-large;
  font-weight: bold;
  border: none;
  background: transparent;
  outline: none;
  color: #133e87;
  cursor: pointer;
  padding: 0;
}

.dictionary-boxTitle:focus {
  outline: none;
}

.dictionary-boxTitle option {
  color: #133e87;
  background-color: #ffffff;
  padding: 10px;
  text-align: center;
  font-size: 1.5rem;
}

.dictionary-box {
  background-color: #cbdceb;
  border: 4px solid #608bc1;
  border-radius: 10px;
  padding: 20px;
  width: 28vw;
  min-height: 600px;
  min-width: 250px;
  max-width: 400px;
  transition: all 0.3s ease;
  box-sizing: border-box;
  display: flex;
  flex-direction: column; /* Stack the content vertically */
  gap: 20px; /* Add spacing between elements inside the box */
}

.dictionary-box:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle hover effect */
}

.dictionary-box-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribute content evenly */
  flex-grow: 1; /* Stretch to fill the box */
}

.dictionary-content-text p {
  margin: 8px 0;
  font-size: 1rem;
  line-height: 1.5;
}

.dictionary-content-text strong {
  font-weight: bold;
  color: #133e87;
}

/* Force to always occupy 3 lines */
.dictionary-content-text p:nth-child(2) {
  /* Translated Word */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to exactly 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.6;
  max-height: calc(2 * 1.6em); /* Ensure max height for 3 lines */
  min-height: calc(2 * 1.6em); /* Ensure minimum height for 3 lines */
  white-space: normal; /* Ensure wrapping of text */
}
.dictionary-content-text p:nth-child(10) {
  /* Translated Word */
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to exactly 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.6;
  max-height: calc(3 * 1.6em); /* Ensure max height for 3 lines */
  min-height: calc(3 * 1.6em); /* Ensure minimum height for 3 lines */
  white-space: normal; /* Ensure wrapping of text */
}

/* Ensure the audio player stays inside the box */
.dictionary-audio-player {
  margin-top: 10px;
  width: 100%;
  max-width: 500px;
  background-color: #f1f1f1;
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .dictionary-box {
    width: 100%; /* Full width on smaller screens */
    max-width: 100%; /* Remove max-width restriction on smaller screens */
  }

  .dictionary-audio-player {
    width: 100%; /* Make the audio player responsive */
  }
}

/*IMAGE*/
.dictionary-image-container {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  background-color: #f3f3f3; /* Light gray placeholder background */
  border: 2px dashed #cbdceb; /* Dashed border for placeholder */
  border-radius: 12px; /* Rounded corners */
  width: 100%; /* Make container width 100% for responsiveness */
  max-width: 1200px; /* Max width for larger screens */
  height: auto; /* Set height to auto for responsive scaling */
  aspect-ratio: 2 / 1; /* Maintain aspect ratio (width:height) */
  margin: 20px auto; /* Center the container */
  overflow: hidden; /* Prevent image overflow */
  position: relative; /* For layering if needed */
}

.dictionary-large-image {
  width: 100%; /* Make the image responsive */
  height: 100%; /* Stretch the image to cover the container */
  object-fit: cover; /* Crop the image to fill the container */
  border-radius: 12px; /* Match the container's rounded corners */
}

/* Placeholder text styling */
.dictionary-image-container::before {
  content: "Image not available"; /* Placeholder text */
  font-size: 1.5rem;
  color: #a1a1a1; /* Subtle gray text */
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  visibility: visible;
  z-index: 1; /* Ensure placeholder text is above everything */
  padding: 10px; /* Add some padding to avoid text touching the border */
}

/* Hide placeholder text when an image is present */
.dictionary-image-container img {
  z-index: 2; /* Ensure image appears above placeholder text */
}

.dictionary-image-container img + ::before {
  visibility: hidden;
}

/* Media Queries for Small Screens */
@media (max-width: 768px) {
  .dictionary-image-container {
    max-width: 100%; /* Full width on smaller screens */
    height: 400px; /* Fixed height for smaller screens */
    aspect-ratio: 4 / 3; /* Adjust aspect ratio for better mobile view */
  }

  .dictionary-large-image {
    object-fit: contain; /* Ensure the image fits well within the container on small screens */
  }

  .dictionary-image-container::before {
    font-size: 1.2rem; /* Smaller placeholder text on mobile */
    padding: 5px; /* Reduced padding for small screens */
  }
}

@media (max-width: 480px) {
  .dictionary-image-container {
    height: 300px; /* Even smaller height for very small screens */
  }

  .dictionary-large-image {
    object-fit: contain; /* Maintain responsive behavior */
  }

  .dictionary-image-container::before {
    font-size: 1rem; /* Even smaller placeholder text */
    padding: 2px; /* Further reduce padding on small screens */
  }
}

/*LOGIN BUTTON*/
.login-button {
  background-color: #608bc1;
  color: white;
  padding: 12px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-align: center;
  width: 200px;
  display: block;
  margin: 20px auto;
}

.login-button:hover {
  background-color: #cbdceb;
  color: black;
}

.login-button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
