.settings-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .settings-form {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input[type="email"],
  .form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .languages-container {
    margin-top: 15px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .language-checkbox {
    display: flex;
    align-items: center;
    margin: 8px 0;
  }
  
  .language-checkbox input[type="checkbox"] {
    margin-right: 8px;
  }
  
  .submit-button {
    background-color: #133e87;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 15px;
  }
  
  .submit-button:hover {
    background-color: #0f2f66;
  }
  
  .error-message {
    background-color: #ffebee;
    color: #c62828;
    padding: 10px;
    border-radius: 4px;
    margin: 10px 0;
  }
  
  .success-message {
    background-color: #e8f5e9;
    color: #2e7d32;
    padding: 10px;
    border-radius: 4px;
    margin: 10px 0;
  }
  
  .users-list {
    margin-top: 30px;
  }
  
  .users-grid {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .user-header {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr 1fr;
    gap: 10px;
    padding: 15px;
    background-color: #608bc1;
    color: white;
    font-weight: bold;
  }
  
  .user-row {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr 1fr;
    gap: 10px;
    padding: 15px;
    border-bottom: 1px solid #ddd;
  }
  
  .user-row:last-child {
    border-bottom: none;
  }
  
  .delete-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }


  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .confirm-dialog {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 90%;
  }
  
  .confirm-dialog h3 {
    margin-top: 0;
    color: #333;
  }
  
  .dialog-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }
  
  .confirm-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .cancel-button {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .confirm-button:hover {
    background-color: #c82333;
  }
  
  .cancel-button:hover {
    background-color: #5a6268;
  }